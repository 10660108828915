import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import headerLogo from '../static/logo2.png'
import '../static/Navbar.css'

class Navbar extends Component {
  

  render() {

    return (
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
      <div className="container">
      <Link className="navbar-brand" to="/"><img alt="logo" src={headerLogo} height="72px" width = "140px"></img></Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExample10"
          aria-controls="navbarsExample10"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse justify-content-end " id="navbarsExample10">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link" >
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/" role="button" aria-haspopup="true" aria-expanded="false"><b>Services</b></a>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to="/body-picker">Residential Moving Services</Link>
            <Link className="dropdown-item" to="/searchworkouts">Packing and deliveries</Link>
            <Link className="dropdown-item" to="/createworkouts">Automative Transport</Link>
            
          </div>
        </li>

        <li className="nav-item">
          <Link to="/About" className="nav-link">
            About Us
          </Link>
        </li>
        <li className="nav-item">
        <Link to="/Quotes" className="nav-link">
        Quotes & Estimates
        </Link>
        </li>
        <li className="nav-item">
        <Link to="/Founder" className="nav-link">
        Our Founder
        </Link>
        </li>
          </ul>
        </div>
        
      </div>
      </nav>
    )
  }
}

export default withRouter(Navbar)