import React from 'react';
import logo from './logo.svg';
import Landing from './Components/Landing';
import { BrowserRouter as HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'

//Comment to test 

function App() {
  return (
    <HashRouter>
        <div className="App">
          <Navbar />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Redirect from="*" to="/404" />    
          </Switch>
          <Footer/>
        </div>
      </HashRouter>
  );
}

export default App;
