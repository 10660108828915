import Carousel from '../Carousel/Carousel'
import React, { Component } from 'react'
import '../static/TextBlock.css'
class Landing extends Component {
render (){
    return(
        <div id="landing">
        <div className="row">
          <div className="col-sm-12 mx-auto">
              <br/><br/><br/><br/><br/><br/>
            <h1> Welcome to LA Delivery 4 U! The delivery </h1>
            <hr/>
            <br/>
          </div>
        </div>
        <Carousel/>
        <div class = "top">
            <div class = "container">
                <div class = "row home-pad" id = "bottom">
                    <div class = "col-xs-12 text center">

                        <div class = "col-sm-4 border-right">
                            <h2>
                                <i class = "fa fa-location-arrow">
                                </i>
                            </h2>
                            <h3>Location</h3>
                            <p>LA Delivery 4 U is a locally owned business that services the greater Los Angeles Area.</p>
                        </div>

                        <div class =" col-sm-4 border right">
                            <h2>
                                <i class = "fa fa-clock-o">

                                </i>
                            </h2>
                            <h3>
                                Anytime
                            </h3>
                            <p>
                                We are available to fit your scheduling needs, and will be there when you need us. Email us or call in order to book your next moving service.
                            </p>
                        </div>

                        <div class = "col-sm-4">
                            <h2>
                                <i class = "fa fa-life-ring">

                                </i>
                            </h2>
                            <h3>
                                Securely
                            </h3>
                            <p>
                                Our goal is to ensure your next moving service is accomplished with the upmost care and cleanliness.
                            </p>
                        </div>
                </div>
            </div>
        </div>
      </div>
      </div>
    )
}
}
export default Landing;